import * as React from 'react'

interface Props {}
interface State {
  hasError: boolean
}

class ErrorBoundary extends React.Component<Props, State> {
  state = {
    hasError: false,
  }

  componentDidCatch(error: Error | null, info: React.ErrorInfo) {
    this.setState({ hasError: true })
    console.error(error, info)
  }

  render() {
    if (this.state.hasError) {
      return <div>Unhandled Error</div>
    } else {
      return this.props.children
    }
  }
}

export default ErrorBoundary
