import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import ErrorBoundary from '~/app/preseed-table/component/ErrorBoundary'
import './index.css'

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>Rooster.haus</BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
)
